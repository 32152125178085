var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcumbs},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{staticClass:"text-subtitle-2 crumb-item",attrs:{"to":item.href,"disabled":item.disabled,"exact":""}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('v-dialog',{attrs:{"max-width":"1024px"},model:{value:(_vm.newCustomerDialog),callback:function ($$v) {_vm.newCustomerDialog=$$v},expression:"newCustomerDialog"}},[_c('div',{staticClass:"card text-white elevation-5"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.newCustomerDetailsHeader,"items":_vm.newCustomerDetailsLists,"loading":_vm.newCustomerDetailsLoading,"dense":""}})],1)])]),_c('v-dialog',{attrs:{"max-width":"1024px"},model:{value:(_vm.newOutletDialog),callback:function ($$v) {_vm.newOutletDialog=$$v},expression:"newOutletDialog"}},[_c('div',{staticClass:"card text-white elevation-5"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.newOutletDetailsHeader,"items":_vm.newOutletDetailsLists,"loading":_vm.newOutletDetailsLoading,"dense":""}})],1)])]),_c('v-dialog',{attrs:{"max-width":"1024px"},model:{value:(_vm.newVisitDialog),callback:function ($$v) {_vm.newVisitDialog=$$v},expression:"newVisitDialog"}},[_c('div',{staticClass:"card text-white elevation-5"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.newVisitDetailsHeader,"items":_vm.newVisitDetailsLists,"loading":_vm.newVisitDetailsLoading,"dense":""}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-12"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Total Sales")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer1"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-12"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Total Kunjungan")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer5"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Commodity External")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer3"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Commodity Internal")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer4"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Visitor Category")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer6"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3"},[_c('div',{staticClass:"card elevation-5"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title"},[_vm._v("Visitor Activity")]),_c('div',{staticStyle:{"height":"300px","width":"100%"},attrs:{"id":"chartContainer7"}})])])])
}]

export { render, staticRenderFns }